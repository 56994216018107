<template>
    <div class="bg-f">
        <!--公共头部-->
        <Header></Header>
        <!-- 用户中心头部 -->
        <user-header />
        <!-- 内容 -->
        <div class="main-content">
            <div class="container">
                <div class="accout-content clearfix" style="display:flex">
                    <!-- 左边 -->
                    <user-left mark="employee"  :userId="userId"/>
                    <!-- 右边 -->
                    <a-card :bordered="false" class="card-right">
                        <div class="first">
                            <span>{{ $t('user.account') }}:</span>
                            <input type="text" v-model="list.account">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.info.last-name') }}:</span>
                            <input type="text" v-model="list.firstName">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.info.first-name') }}:</span>
                            <input type="text" v-model="list.lastName">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.info.tel') }}:</span>
                            <input type="text" v-model="list.phone">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.info.email') }}:</span>
                            <input type="text" v-model="list.email">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.info.position') }}:</span>
                            <input type="text" v-model="list.position">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.status') }}:</span>
                            <a-radio-group v-model="status" @change="onChange">
                                <a-radio :value="-1">
                                    {{ $t('user.disable') }}
                                </a-radio>
                                <a-radio :value="0">
                                    {{ $t('user.wait-check') }}
                                </a-radio>
                                <a-radio :value="1">
                                    {{ $t('user.enable') }}
                                </a-radio>
                            </a-radio-group>
                        </div>
                        <button class="submit" @click="regsub()">{{ $t('user.submit') }}</button>
                    </a-card>
                </div>
            </div>
        </div>
        <!-- 公共尾部 -->
        <Footer></Footer>
    </div>
</template>

<style>
    .main-content .card-right{
        width: 100%;
    }
    .first,.second{
        text-align: left;
    }
    .first>span,.second>span{
        font-size: 24px;
        width: 120px;
        display: inline-block;
    }
    input[type=text]{
      width: 300px;
      border: 1px solid #787878;
      margin-left: 10px;
    }
    .second{
        margin-top: 20px;
    }
    .submit{
        width: 200px;
        height: 40px;
        margin-top: 40px;
        border: 1px solid  #d9d9d9;
        line-height: 40px;
        background: #ff4d4f;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
    }
    .ant-radio-group{
        margin-left: 10px;
    }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'EmplyAdd',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        id: this.$route.params.id,
        list: [],
        status: 0
    }
  },
  mounted () {
    this.getList()
  },
   methods: {
    onChange(e) {
      console.log('radio checked', e.target.value);
      this.status = e.target.value
    },
    // 获取账号信息
    getList () {
       this.$common.fetchList('/user/childs/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    //子账号修改
    regsub() {
        const params = {
            account: this.list.account,
            firstName: this.list.firstName,
            lastName: this.list.lastName,
            phone: this.list.phone,
            email: this.list.email,
            position: this.list.position,
            status: this.status
        }
        this.$common.handlePost('/user/childs/edit/'+this.id, params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'UsersEmployee', params: {userId: this.userId}})
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>
